import indexStyles from '../sass/index.scss';
let commons = require('./utils/commons');
import mainSlider1IMG from '../img/main-slider-1.png';
import mainSlider1XsIMG from '../img/main-slider-1-xs.png';
import promoBkg1IMG from '../img/promo-bkg-1.png';
import promoBkg2IMG from '../img/promo-bkg-2.png';
import promoBkg3IMG from '../img/promo-bkg-3.png';
import promoBkg4IMG from '../img/promo-bkg-4.png';
import bkgFrontPointIMG from '../img/bkg-front-point.png';
import bkgFrontPointXsIMG from '../img/bkg-front-point-xs.png';
import bouquet1IMG from '../img/bouquet-1.png';
import bouquet2IMG from '../img/bouquet-2.png';
import bouquet3IMG from '../img/bouquet-3.png';
import bouquet4IMG from '../img/bouquet-4.png';
import reviewAuthor1IMG from '../img/review-author-1.png';
import reviewAuthorDefaultIMG from '../img/review-author-default.png';
import instagram1IMG from '../img/instagram-1.png';
import instagram2IMG from '../img/instagram-2.png';
import instagram3IMG from '../img/instagram-3.png';
import instagram4IMG from '../img/instagram-4.png';
import instagram5IMG from '../img/instagram-5.png';
import instagram6IMG from '../img/instagram-6.png';
import instagram7IMG from '../img/instagram-7.png';
import instagram8IMG from '../img/instagram-8.png';
import instagram9IMG from '../img/instagram-9.png';
import greenhouse1IMG from '../img/greenhouse-1.png';
import article1IMG from '../img/article-1.png';
import article2IMG from '../img/article-2.png';
import article3IMG from '../img/article-3.png';
import article4IMG from '../img/article-4.png';
import article5IMG from '../img/article-5.png';
import article6IMG from '../img/article-6.png';
